import React, {useEffect, useRef} from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Banner from "../../../components/banner"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Emisión de Deuda"/>
            <Banner title="Emisión de Deuda"/>
            <section className="service_details_area aspectos servicios">
                <div className="container">
                    <div className="section_title black_c">
                        <p className="f_16">Este paquete de soluciones se enfoca a aquellas empresas que cuentan con la
                            capacidad
                            necesaria de emitir deuda (hasta un eurobono registrado), y desean incursionar a los
                            mercados de deuda, a
                            través de una colocación inicial. En esta solución IRStrat se enfoca en facilitar el proceso
                            de colocación,
                            acortar el tiempo de ejecución, y minimizar el costo de la deuda a colocar. Este paquete
                            incluye lo siguiente:</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="service_left_sidebar">
                                <ul className="nav nav-tabs service_tab mb_50" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active show" id="paquete1-tab" data-toggle="tab"
                                           href="#paquete1" role="tab"
                                           aria-controls="paquete1" aria-selected="true">Paquete 1</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="paquete2-tab" data-toggle="tab" href="#paquete2"
                                           role="tab"
                                           aria-controls="paquete2" aria-selected="false">Paquete 2</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="paquete3-tab" data-toggle="tab" href="#paquete3"
                                           role="tab"
                                           aria-controls="paquete3" aria-selected="false">Paquete 3</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade active show" id="paquete1" role="tabpanel"
                                     aria-labelledby="paquete1-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#accordion"
                                                               href="#collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Plan estratégico
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse1" className="collapse show" aria-expanded="true"
                                                         role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Identificación
                                                                    de mercados y tasas de interés
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Prospección de
                                                                    costo de deuda
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Evaluación
                                                                    inicial de alcance de emisión
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Desarrollo de
                                                                    matriz de evaluación de
                                                                    capacidades
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Definición de
                                                                    calendario go-to-market
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Agenda de
                                                                    preparación de Prospecto
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#accordion" href="#collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Núcleo estratégico
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false" role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Desarrollo de
                                                                    tesis de inversión
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Estructuración
                                                                    de ventaja competitiva
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Desarrollo de
                                                                    la narrativa de la historia
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Definición de
                                                                    estilo de comunicación (mix
                                                                    cualitativo y cuantitativo)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Estrategia de
                                                                    divulgación
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#accordion" href="#collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Prospección estratégica
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false" role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Targeting de
                                                                    sindicato colocador
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Asesoría en
                                                                    beauty contest de sindicato colocador
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Targeting de
                                                                    inversores institucionales
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Proxy de book
                                                                    de salida
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Asesoría en
                                                                    road show
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Desarrollo de
                                                                    expedientes informativos de inversionistas
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#accordion" href="#collapse4"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Lobbying
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse4" className="panel-collapse collapse"
                                                         aria-expanded="false" role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Presentaciones
                                                                    con fondos de inversión y pensiones
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Presentaciones
                                                                    con áreas de gestión patrimonial
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Presentaciones
                                                                    con calificadoras
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Acercamiento a
                                                                    medios especializados
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="paquete2" role="tabpanel"
                                     aria-labelledby="paquete2-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="p2_accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#p2_accordion"
                                                               href="#p2_collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="p2_collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Comunicaciones del C-Suite
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse1" className="collapse show"
                                                         aria-expanded="true" role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Entrenamiento
                                                                    del equipo directivo en comunicación a los mercados
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Preparación y
                                                                    asesoría para reuniones cara a cara y presentaciones
                                                                    de road show
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Asesoría en
                                                                    comunicaciones para manejo de crisis
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p2_accordion" href="#p2_collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="p2_collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Estructuración de políticas y
                                                                procedimientos
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Estructuración
                                                                    de política de divulgación
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Mapeo de
                                                                    principales funciones
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Estructuración
                                                                    del diagrama de flujo de las funciones de RI
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p2_accordion" href="#p2_collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Cumplimiento regulatorio</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Máximo
                                                                    cumplimiento con la CNBV
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Máximo
                                                                    cumplimiento con la CONSAR
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Máximo
                                                                    cumplimiento con la BMV
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Máximo
                                                                    cumplimiento con la BIVA
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Máximo
                                                                    cumplimiento con la SEC
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p2_accordion" href="#p2_collapse4"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Cobertura de análisis</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse4" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Desarrollo de
                                                                    modelos de valuación de nodos
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Targeting de
                                                                    inversionistas sell-side
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Presentaciones
                                                                    con áreas de análisis de renta fija
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="paquete3" role="tabpanel"
                                     aria-labelledby="paquete3-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="p3_accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#p3_accordion"
                                                               href="#p3_collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="p3_collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Benchmarking</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse1" className="collapse show"
                                                         aria-expanded="true" role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Definición de
                                                                    sector y “pares”
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparativos de
                                                                    valuaciones
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparativos de
                                                                    resultados
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparativos
                                                                    go-to-market
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p3_accordion" href="#p3_collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="p3_collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Desarrollo de documentos,
                                                                reportes y materiales</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Elaboración,
                                                                    estructuración y circle-up de Prospecto
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Elaboración,
                                                                    estructuración, diseño y circle-up de presentación a
                                                                    inversionistas
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Elaboración,
                                                                    estructuración, diseño y circle-up de investment
                                                                    fact sheet
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p3_accordion" href="#p3_collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Sitio de RI</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Estructuración
                                                                    y desarrollo gráfico
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Elaboración de
                                                                    contenido
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Plataforma
                                                                    móvil optimizada
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Herramientas
                                                                    interactivas (múltiplos de valuación, principales
                                                                    indicadores de desempeño e información bursátil)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p3_accordion" href="#p3_collapse4"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Servicios IR Edge 3.0</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse4" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Herramienta de
                                                                    CRM para inversionista
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Web Analytics
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Servidores
                                                                    dedicados en la nube
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Servicios de
                                                                    traducción (español-inglés)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Interfase de
                                                                    inteligencia
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default Contenido
